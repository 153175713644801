.footer-v1 {

	.main-footer {

		background-color: #f3f2ed;
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}


		.logo {
			width: auto;
			height: 131px;
			margin-top: -40px;
			padding-bottom: 20px;

			@media (max-width: 767px) {
				height: 101px;
			}
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
					text-decoration: none;
				}

				.fa {
					font-size: 20px;
					width: 35px;
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: rgb(66, 103, 178);
					border-radius: 7px;
					color: #fff;

					&.fa-instagram {
						background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
					}
				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

}