.three-boxes {
	.box-part {
		img {
			background-color: #cec3a0 !important;
			margin-bottom: 20px;
		}

		h3 {
			margin: 0 0 15px 0;
			font-size: 24px;
		}

		p {}
	}
}