@font-face {
	font-family: 'Roboto Condensed';
	src: url('RESOURCE/fonts/RobotoCondensed-Bold.eot');
	src: url('RESOURCE/fonts/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/RobotoCondensed-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/RobotoCondensed-Bold.woff') format('woff'),
		url('RESOURCE/fonts/RobotoCondensed-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/RobotoCondensed-Bold.svg#RobotoCondensed-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('RESOURCE/fonts/RobotoCondensed-Light.eot');
	src: url('RESOURCE/fonts/RobotoCondensed-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/RobotoCondensed-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/RobotoCondensed-Light.woff') format('woff'),
		url('RESOURCE/fonts/RobotoCondensed-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/RobotoCondensed-Light.svg#RobotoCondensed-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('RESOURCE/fonts/RobotoCondensed-Regular.eot');
	src: url('RESOURCE/fonts/RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/RobotoCondensed-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/RobotoCondensed-Regular.woff') format('woff'),
		url('RESOURCE/fonts/RobotoCondensed-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/RobotoCondensed-Regular.svg#RobotoCondensed-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('RESOURCE/fonts/RobotoCondensed-LightItalic.eot');
	src: url('RESOURCE/fonts/RobotoCondensed-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/RobotoCondensed-LightItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/RobotoCondensed-LightItalic.woff') format('woff'),
		url('RESOURCE/fonts/RobotoCondensed-LightItalic.ttf') format('truetype'),
		url('RESOURCE/fonts/RobotoCondensed-LightItalic.svg#RobotoCondensed-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Tahoma';
	src: url('RESOURCE/fonts/Tahoma.eot');
	src: url('RESOURCE/fonts/Tahoma.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Tahoma.woff2') format('woff2'),
		url('RESOURCE/fonts/Tahoma.woff') format('woff'),
		url('RESOURCE/fonts/Tahoma.ttf') format('truetype'),
		url('RESOURCE/fonts/Tahoma.svg#Tahoma') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Tahoma';
	src: url('RESOURCE/fonts/Tahoma-Bold.eot');
	src: url('RESOURCE/fonts/Tahoma-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Tahoma-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Tahoma-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Tahoma-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Tahoma-Bold.svg#Tahoma-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}