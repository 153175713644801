.unit-title-v1 {
	h1 {}

	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: middle;

		@media (max-width: 767px) {
			display: block;
			width: 100%;
		}

	}
}